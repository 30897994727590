import React from 'react';


const ZoomDropdown = ({ provinces, setState, brandFilterIsOpen, setBrandFilterIsOpen, qualityFilterIsOpen, setQualityFilterIsOpen }) => {

  const onZoomChange = (event) => {
    const latVal = parseFloat(event.target.value.split(',')[1]);
    const lngVal = parseFloat(event.target.value.split(',')[2]);
    const ZoomVal = parseInt(event.target.value.split(',')[3]);
    setState({ center: [latVal, lngVal], zoom: ZoomVal })
  };

  return (
    <div className="zoom-dropdown-wrapper">
      <select
        id="zoownmDropdown"
        className="zoom-dropdown"
        defaultValue={'DEFAULT'}
        onChange={onZoomChange}
      >
        <option disabled value="DEFAULT">Zooma till</option>
        {Object.entries(provinces).map((item) => (
          <option key={item[0]} value={item}>{item[0]}</option>
        ))}

      </select>
      <div>
        {!brandFilterIsOpen &&
          <button className="map-filter-button brand-color" onClick={() => setBrandFilterIsOpen(true)}>
            <p>Garnmärken</p>
            <img src="./assets/chevron-down.svg" alt="chevron down" className="filter-chevron" />
          </button>
        }
        {brandFilterIsOpen &&
          <button className="map-filter-button brand-color" onClick={() => setBrandFilterIsOpen(false)}>
            <p>Garnmärken</p>
            <img src="./assets/chevron-up.svg" alt="chevron up" className="filter-chevron" />
          </button>
        }
      </div>

      <div>
        {!qualityFilterIsOpen &&
          <button className="map-filter-button quality-color" onClick={() => setQualityFilterIsOpen(true)}>
            Egenskaper
            <img src="./assets/chevron-down.svg" alt="chevron down" className="filter-chevron" />
          </button>
        }
        {qualityFilterIsOpen &&
          <button className="map-filter-button quality-color" onClick={() => setQualityFilterIsOpen(false)}>
            Egenskaper
            <img src="./assets/chevron-up.svg" alt="chevron up" className="filter-chevron" />
          </button>
        }
      </div>

    </div>
  )
};
export default ZoomDropdown;

