import React from 'react';
// import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'

//import destination from '../reducers/destination';

const BlogLink = () => {
  // const dispatch = useDispatch();

  return (
    <div>
      <NavLink to='/blogg'
      // onClick={() => {
      //   dispatch(destination.actions.setSelectedDestination(null))
      // }}
      >
        <p className="navbar-item">Blogg</p>
      </NavLink>
    </div>
  )
};

export default BlogLink;