import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  destinations: [],
  chosenZoomCoordinates: [],
  chosenZoomLevel: undefined,
  selectedDestination: undefined,
  destinationId: undefined,
  filteredDestinations: [],
  qualityFilter: [],
  brandFilter: undefined,
  loading: false,
  isFilteredOnQualities: false,
  isFilteredOnBrand: false,
}

const destination = createSlice({
  name: 'destination',
  initialState,
  reducers: {
    setDestinations: (store, action) => {
      store.destinations = action.payload
    },
    setChosenZoomCoordinates: (store, action) => {
      store.chosenZoomCoordinates = action.payload
    },
    setChosenZoomLevel: (store, action) => {
      store.chosenZoomLevel = action.payload
    },
    setDestinationId: (store, action) => {
      store.destinationId = action.payload
    },
    setSelectedDestination: (store, action) => {
      store.selectedDestination = action.payload
    },
    setQualityFilter: (store, action) => {
      const { quality, value } = action.payload;
      if (value === true) {
        return {
          ...store,
          qualityFilter: [...store.qualityFilter, quality]
        }
      } else {
        return {
          ...store,
          qualityFilter: store.qualityFilter.filter(item => item !== quality)
        }
      }
    },
    clearQualityFilter: (store) => {
      return {
        ...store,
        qualityFilter: []
      }
    },
    setBrandFilter: (store, action) => {
      store.brandFilter = action.payload
    },
    clearBrandFilter: (store) => {
      return {
        ...store,
        brandFilter: undefined
      }
    },
    setLoading: (store, action) => {
      store.loading = action.payload;
    },
    setFilteredDestinations: (store, action) => {
      store.filteredDestinations = action.payload
    },
    setIsFilteredOnQualities: (store, action) => {
      store.isFilteredOnQualities = action.payload
    },
    setIsFilteredOnBrand: (store, action) => {
      store.isFilteredOnBrand = action.payload
    },
  }
})


export const getData = () => {
  return (dispatch) => {
    dispatch(destination.actions.setLoading(true));

    const options = {
      method: 'GET',
    };
    //fetch("http://localhost:8080/shops", options)
    fetch("https://demo-garnkartan.herokuapp.com/shops", options)
      .then(res => res.json())
      .then(data => dispatch(destination.actions.setDestinations(data.shops)))
      .finally(() => dispatch(destination.actions.setLoading(false)));
  }
}

export const filterShops = () => {
  return (dispatch, getState) => {
    dispatch(destination.actions.setLoading(true));
    const originalShops = getState().destination.destinations;
    const chosenQualities = getState().destination.qualityFilter;
    const brandFilter = getState().destination.brandFilter;
    let filteredResults;
    if (chosenQualities) {
      if (brandFilter) {
        console.log("Filter by qualities and brand");
        const filteredByQualityResults = originalShops.filter(item => (Object.values(chosenQualities).every(v => Object.entries(item.qualities).filter(([key, value]) => value === true).map(i => i[0]).includes(v))));
        filteredResults = filteredByQualityResults.filter(item => Object.values(item.brands).includes(brandFilter));
        console.log(brandFilter)
      } else {
        console.log("Filter only by qualitites")
        filteredResults = originalShops.filter(item => (Object.values(chosenQualities).every(v => Object.entries(item.qualities).filter(([key, value]) => value === true).map(i => i[0]).includes(v))))
        console.log(chosenQualities)
      }
    } else if (brandFilter) {
      console.log("Filter only by brand");
      filteredResults = originalShops.filter(item => Object.values(item.brands).includes(brandFilter))
    };
    dispatch(destination.actions.setFilteredDestinations(filteredResults));
    dispatch(destination.actions.setLoading(false))
  }
}

export default destination