import React, { useEffect, useState } from 'react';
import sanityClient from "../client";

const AdSpace = () => {

  const [adData, setAdData] = useState(null);

  useEffect(() => {

    sanityClient
      .fetch(
        `*[_type == "adMobileMap"] {
        publishedAt,
        title,
        adUrl,
        adImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAdData(data[0]))
      .catch(console.error);
  }, []);

  console.log(adData);
  return (
    <>
      {adData &&
        <a href={adData.adUrl} className="ad-space-wrapper">
          <img src={adData.adImage.asset.url} alt="annonsbild" className="ad-space" />
        </a>
      }
    </>
  )
};

export default AdSpace;