import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import destination, { filterShops } from "../reducers/destination";

const FilterQualities = ({ setQualityFilterIsOpen }) => {
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);

  const dispatch = useDispatch();

  const qualityFilter = useSelector(store => store.destination.qualityFilter);

  //Tick checkboxes if qualities are saved in store
  useEffect(() => {
    if (qualityFilter.includes('organic')) {
      setChecked1(true)
    };
    if (qualityFilter.includes('fairTrade')) {
      setChecked2(true)
    }
    if (qualityFilter.includes('swedishWool')) {
      setChecked3(true)
    }
    if (qualityFilter.includes('handDyed')) {
      setChecked4(true)
    }
    if (qualityFilter.includes('recycled')) {
      setChecked5(true)
    }
    if (qualityFilter.includes('hasWebShop"')) {
      setChecked6(true)
    }
    if (qualityFilter.includes('ownProduction')) {
      setChecked7(true)
    }
    if (qualityFilter.includes('events')) {
      setChecked8(true)
    }
  }, [qualityFilter]);

  //One for each shop quality

  const handleChange1 = (e) => {
    setChecked1(!checked1);
    dispatch(destination.actions.setQualityFilter({ quality: e.target.value, value: !checked1 }));
    dispatch(filterShops());
    dispatch(destination.actions.setIsFilteredOnQualities(true))
  };
  const handleChange2 = (e) => {
    setChecked2(!checked2);
    dispatch(destination.actions.setQualityFilter({ quality: e.target.value, value: !checked2 }));
    dispatch(filterShops());
    dispatch(destination.actions.setIsFilteredOnQualities(true))
  };
  const handleChange3 = (e) => {
    setChecked3(!checked3);
    dispatch(destination.actions.setQualityFilter({ quality: e.target.value, value: !checked3 }));
    dispatch(filterShops());
    dispatch(destination.actions.setIsFilteredOnQualities(true))
  };
  const handleChange4 = (e) => {
    setChecked4(!checked4);
    dispatch(destination.actions.setQualityFilter({ quality: e.target.value, value: !checked4 }));
    dispatch(filterShops());
    dispatch(destination.actions.setIsFilteredOnQualities(true))
  };
  const handleChange5 = (e) => {
    setChecked5(!checked5);
    dispatch(destination.actions.setQualityFilter({ quality: e.target.value, value: !checked5 }));
    dispatch(filterShops());
    dispatch(destination.actions.setIsFilteredOnQualities(true))
  };
  const handleChange6 = (e) => {
    setChecked6(!checked6);
    dispatch(destination.actions.setQualityFilter({ quality: e.target.value, value: !checked6 }));
    dispatch(filterShops());
    dispatch(destination.actions.setIsFilteredOnQualities(true))
  };
  const handleChange7 = (e) => {
    setChecked7(!checked7);
    dispatch(destination.actions.setQualityFilter({ quality: e.target.value, value: !checked7 }));
    dispatch(filterShops());
    dispatch(destination.actions.setIsFilteredOnQualities(true))
  };
  const handleChange8 = (e) => {
    setChecked8(!checked8);
    dispatch(destination.actions.setQualityFilter({ quality: e.target.value, value: !checked8 }));
    dispatch(filterShops());
    dispatch(destination.actions.setIsFilteredOnQualities(true))
  };

  const handleFilterReset = () => {
    setChecked1(false);
    setChecked2(false);
    setChecked3(false);
    setChecked4(false);
    setChecked5(false);
    setChecked6(false);
    setChecked7(false);
    setChecked8(false);
    dispatch(destination.actions.clearQualityFilter());
    dispatch(filterShops());
    dispatch(destination.actions.setIsFilteredOnQualities(false))
  }

  return (
    <div className="map-filter-accordion qualities">
      <div className="map-filter-container">
        <div className="map-filter-segment">
          <div className="map-filter-option">
            <input className="check-box" type="checkbox" checked={checked1} onChange={handleChange1} id="organic" name="organic" value="organic" />
            <label className="map-filter-label" htmlFor="organic"> Ekologiskt </label>
          </div>
          <div className="map-filter-option">
            <input className="check-box" type="checkbox" checked={checked2} onChange={handleChange2} id="fairTrade" name="fairTrade" value="fairTrade" />
            <label className="map-filter-label" htmlFor="fairTrade"> Fairtrade </label>
          </div>
          <div className="map-filter-option">
            <input className="check-box" type="checkbox" checked={checked3} onChange={handleChange3} id="swedishWool" name="swedishWool" value="swedishWool" />
            <label className="map-filter-label" htmlFor="swedishWool"> Svensk ull </label>
          </div>
          <div className="map-filter-option">
            <input className="check-box" type="checkbox" checked={checked4} onChange={handleChange4} id="handDyed" name="handDyed" value="handDyed" />
            <label className="map-filter-label" htmlFor="handDyed"> Handfärgat </label>
          </div>
          <div className="map-filter-option">
            <input className="check-box" type="checkbox" checked={checked5} onChange={handleChange5} id="recycled" name="recycled" value="recycled" />
            <label className="map-filter-label" htmlFor="recycled"> Återvunna material </label>
          </div>
          <div className="map-filter-option">
            <input className="check-box" type="checkbox" checked={checked6} onChange={handleChange6} id="hasWebShop" name="hasWebShop" value="hasWebShop" />
            <label className="map-filter-label" htmlFor="hasWebShop"> Har webbshop </label>
          </div>
          <div className="map-filter-option">
            <input className="check-box" type="checkbox" checked={checked7} onChange={handleChange7} id="ownProduction" name="ownProduction" value="ownProduction" />
            <label className="map-filter-label" htmlFor="ownProduction"> Egen produktion </label>
          </div>
          <div className="map-filter-option">
            <input className="check-box" type="checkbox" checked={checked8} onChange={handleChange8} id="events" name="events" value="events" />
            <label className="map-filter-label" htmlFor="events"> Ordnar stickträffar el. dyl. </label>
          </div>
        </div>
        <div>
          <button className="map-filter-reset-button qualities" onClick={() => handleFilterReset()}>Rensa</button>
        </div>
      </div>

    </div >
  )
};

export default FilterQualities;