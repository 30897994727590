import React from "react";

const Header = () => {
  return (
    <div className="header-container">
      <picture className="logo-container">
        <source srcSet="/assets/logo-pink-tablet.png" media="(max-width: 767px)" />
        <source srcSet="/assets/logo-pink-tablet.png" media="(max-width: 1199px)" />
        <img className="header__header-logo" src="/assets/logo-pink-desktop.png" alt="garnkartan logo" />
      </picture>
    </div>

  )
}
export default Header;