import React from "react";
import BlogLink from "./BlogLink";
import HomeLink from "./HomeLink";
import MapLink from "./MapLink";

const Navbar = () => {
  return (
    <div className="navbar">
      <HomeLink />
      <BlogLink />
      <MapLink />
    </div>
  )
};
export default Navbar