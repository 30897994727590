import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from "./components/Header";
import HeaderPlaceholder from "./components/HeaderPlaceholder";
import Yarnmap from "./pages/Yarnmap";
import Navbar from "./components/Navbar";
import Loader from "./components/Loader";

const SelectedDestination = lazy(() => import("./pages/SelectedDestination"));
const Home = lazy(() => import("./pages/Home"));
const Blog = lazy(() => import("./pages/Blog"));

const App = () => {

  const selectedDestination = useSelector((store) => store.destination.selectedDestination)

  return (
    <div className="app">
      <BrowserRouter>
        <Header />
        <HeaderPlaceholder />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route element={<Home />} path='om' />
            <Route element={<Yarnmap />} path='*' />
            <Route element={<Blog />} path='blogg/*' />
            <Route element={selectedDestination ? <SelectedDestination /> : <Yarnmap />} path='destination' />

          </Routes>
        </Suspense>
        <Navbar />
      </BrowserRouter>
    </div >
  );
}

export default App;
