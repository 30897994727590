import React, { useEffect, useState } from 'react';
import sanityClient from "../client";

const AdSpaceDesktop = () => {
  const [adData, setAdData] = useState(null);

  useEffect(() => {

    sanityClient
      .fetch(
        `*[_type == "adsDesktopMap"] {
        publishedAt,
        title,
        adUrl,
        adImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAdData(data))
      .catch(console.error);
  }, []);

  console.log(adData);

  return (
    <div className="ad-space-desktop-container">
      {adData &&
        adData.map((ad, index) => (
          <a href={ad.adUrl} key={index} className="ad-space-wrapper-desktop">
            <img src={ad.adImage.asset.url} alt="annonsbild" className="ad-space" />
          </a>
        ))}
    </div>


  )
};

export default AdSpaceDesktop;