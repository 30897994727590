import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import destination, { getData } from "../reducers/destination";

const MapLink = () => {
  const dispatch = useDispatch();
  return (
    <div>
      <NavLink to='/'
        onClick={() => {
          dispatch(destination.actions.setSelectedDestination(null));
          dispatch(getData());
        }}
      >
        <p className="navbar-item">Karta</p>
      </NavLink>
    </div>
  )
};

export default MapLink;