import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import destination, { filterShops } from "../reducers/destination";






const FilterYarnBrands = ({ setFilterIsOpen }) => {
  const [checked, setChecked] = useState(false);
  const [chosenBrand, setChosenBrand] = useState("");
  const brandFilter = useSelector(store => store.destination.brandFilter);

  const dispatch = useDispatch();

  const originalShops = useSelector(store => store.destination.destinations);


  //Get list of all existing brands amongst shops (Might swap for endpoint data later)
  const allBrandsArray = originalShops.map(item => Object.values(item.brands));
  const UnNestedBrandsArray = [].concat.apply([], allBrandsArray);
  const UnNestedBrandsArrayNoDuplicates = UnNestedBrandsArray.filter((element, index) => {
    return UnNestedBrandsArray.indexOf(element) === index;
  });

  console.log(UnNestedBrandsArrayNoDuplicates)

  useEffect(() => {
    setChosenBrand(brandFilter)
  }, [brandFilter])

  console.log(chosenBrand)
  const handleChange = (e) => {
    dispatch(destination.actions.clearBrandFilter());
    setChecked(!checked);
    dispatch(destination.actions.setBrandFilter(e.target.value));
    dispatch(filterShops());
    dispatch(destination.actions.setIsFilteredOnBrand(true))
  };

  const handleFilterReset = () => {
    setChecked(false);
    dispatch(destination.actions.clearBrandFilter());
    dispatch(filterShops());
    dispatch(destination.actions.setIsFilteredOnBrand(false))
  }

  return (
    <div className="map-filter-accordion brands">
      <div>
        <button className="map-filter-reset-button" onClick={() => handleFilterReset()}>Rensa</button>
      </div>
      <form className="brand-form">
        {UnNestedBrandsArrayNoDuplicates.sort().map((brand, index) => (
          <p key={brand}>
            <label >
              <input className="radio-button" type="radio" id={brand} name="brandChoice" value={brand} key={index} checked={chosenBrand === brand} onChange={handleChange} />
              {brand}
            </label>
          </p>
        ))
        }
      </form>
    </div >
  )
};

export default FilterYarnBrands;