import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Map, ZoomControl, Overlay } from "pigeon-maps";

import destination, { getData } from "../reducers/destination";
import Loader from "../components/Loader";
import ZoomDropdown from "../components/ZoomDropdown";
import FilterQualities from "../components/FilterQualities";
import FilterYarnBrands from "../components/FilterYarnBrands";
import AdSpace from "../components/AdSpace.js";
import AdSpaceBelow from "../components/AdSpaceBelow";
import AdSpaceDesktop from "../components/AdSpaceDesktop";
import { provinces } from '../data/provinces';
import AdSpaceTablet from "../components/AdSpaceTablet";



const Yarnmap = () => {
  const [brandFilterIsOpen, setBrandFilterIsOpen] = useState(false);
  const [qualityFilterIsOpen, setQualityFilterIsOpen] = useState(false);

  const savedZoom = useSelector(store => store.destination.chosenZoomLevel);
  const savedCoordinates = useSelector(store => store.destination.chosenZoomCoordinates);

  //Maptiler provider

  const MAPTILER_ACCESS_TOKEN = "0RnU4jneiMuBrfDwWxnC"
  const MAP_ID = '2324bf77-b2d4-4acf-8c17-969bd5409ca4'

  function mapTiler(x, y, z, dpr) {
    return `https://api.maptiler.com/maps/${MAP_ID}/256/${z}/${x}/${y}${dpr >= 2 ? '@2x' : ''}.png?key=${MAPTILER_ACCESS_TOKEN}`
  }

  //States and functions related to Pigeon-maps
  const [state, setRawState] = useState({
    center: savedCoordinates.length > 0 ? savedCoordinates : [63.196, 15.817],
    zoom: savedZoom ? savedZoom : 4,
    provider: 'osm',
    animate: true,
    animating: false,
    minZoom: 1,
    maxZoom: 18,
  })
  const setState = (stateChanges) => setRawState({ ...state, ...stateChanges });
  const {
    center,
    zoom,
    animate,
    minZoom,
    maxZoom,
  } = state;

  const handleBoundsChange = ({ center, zoom, bounds, initial }) => {
    if (initial) {
      console.log('Got initial bounds: ', bounds)
    }
    setState({ center, zoom })
  }

  const handleClick = ({ event, latLng, pixel }) => {
    console.log('Map clicked!', latLng, pixel)
  }
  const handleAnimationStart = () => {
    setState({ animating: true })
  }

  const handleAnimationStop = () => {
    setState({ animating: false })
  }
  //End of states and functions related to Pigeon-map

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* const loading = true; */
  const loading = useSelector(store => store.destination.loading);
  const originalShops = useSelector(store => store.destination.destinations);
  const filteredShops = useSelector(store => store.destination.filteredDestinations);
  const isFilteredQualities = useSelector(store => store.destination.isFilteredOnQualities);
  const isFilteredBrand = useSelector(store => store.destination.isFilteredOnBrand);

  //Update shop data if browser window is refreshed
  window.onload = () => {
    dispatch(getData());
  }

  //Save zoom values to store
  useEffect(() => {
    dispatch(destination.actions.setChosenZoomCoordinates(state.center));
    dispatch(destination.actions.setChosenZoomLevel(state.zoom));
  }, [state.zoom, state.center, dispatch]);

  let shops = [];
  if (isFilteredBrand === true || isFilteredQualities === true) {
    shops = filteredShops
  } else {
    shops = originalShops;
  };

  console.log(shops)
  console.log(state.zoom)

  const YarnMarker = ({ left, top, style, children }) => (
    <div style={{
      position: 'absolute',
      left: left,
      top: top,
      ...(style || {})
    }}>{children}</div>
  )

  let mapHeight;
  // let mapWidth;
  let bigScreen = false;
  let tallMobile = false;
  let tablet = false;
  let yarnBall;
  let yarnBallWidth;
  let yarnBallHeight;
  let markerOffset;

  //change marker and label with zoom

  if (state.zoom > 12) {
    yarnBall = 'url(./assets/yarnball-pink-mobile.png)';
    yarnBallWidth = 30;
    yarnBallHeight = 30;
    markerOffset = [23, 25]
  } else {
    yarnBall = 'url(./assets/yarnball-pink-mobile.png)';
    yarnBallWidth = 30;
    yarnBallHeight = 30;
    markerOffset = [23, 25]
    // yarnBall = 'url(./assets/pinkCircle.png)';
    // yarnBallWidth = 7;
    // yarnBallHeight = 7;
    // markerOffset = [3, 5]
  }



  if (window.innerWidth < 768 && window.innerHeight <= 739) {
    // mapHeight = 400;
    mapHeight = window.innerHeight - 196;
    // mapHeight = window.innerHeight * 0.6;
    // mapWidth = window.innerWidth;
    tallMobile = false;
    //yarnBall = 'url(./assets/yarnball-pink-mobile.png)';
  } else if (window.innerWidth < 768 && window.innerHeight > 739) {
    mapHeight = window.innerHeight - 204;
    // mapHeight = 400;
    // mapHeight = window.innerHeight * 0.6;
    // mapWidth = window.innerWidth;
    tallMobile = true;
    //yarnBall = 'url(./assets/yarnball-pink-mobile.png)';
  } else if (window.innerWidth <= 1200) {
    mapHeight = window.innerHeight - 212;
    // mapWidth = window.innerWidth;
    //yarnBall = 'url(./assets/yarnball-pink-mobile.png)';
    tallMobile = false;
    tablet = true;
  } else if (window.innerWidth > 1200) {
    mapHeight = window.innerHeight * 0.6;
    // mapWidth = window.innerWidth * 0.6;
    //yarnBall = 'url(./assets/yarnball-pink-desktop.png)';
    bigScreen = true;
    tallMobile = false;
  }
  console.log(tallMobile);

  return (
    <div className="map-container">
      <div className="inner-map-container">
        <ZoomDropdown
          setState={setState}
          setBrandFilterIsOpen={setBrandFilterIsOpen}
          brandFilterIsOpen={brandFilterIsOpen}
          setQualityFilterIsOpen={setQualityFilterIsOpen}
          qualityFilterIsOpen={qualityFilterIsOpen}
          provinces={provinces} />

        {brandFilterIsOpen &&
          <FilterYarnBrands
            setState={setState}
            setFilterIsOpen={setBrandFilterIsOpen}
            provinces={provinces}
          />
        }
        {qualityFilterIsOpen &&
          <FilterQualities
            setState={setState}
            setFilterIsOpen={setQualityFilterIsOpen}
            provinces={provinces}
          />
        }
        {!tallMobile && !bigScreen && !tablet && < AdSpace />}
        {tablet && <AdSpaceTablet />}
        {loading &&
          <Loader />}
        {!loading &&
          <div className="map-wrapper">

            <Map
              provider={mapTiler}
              dprs={[1, 2]}
              height={mapHeight}
              // width={mapWidth}
              center={center}
              zoom={zoom}
              limitBounds="edge"
              onBoundsChanged={handleBoundsChange}
              onClick={handleClick}
              onAnimationStart={handleAnimationStart}
              onAnimationStop={handleAnimationStop}
              animate={animate}
              minZoom={minZoom}
              maxZoom={maxZoom}
            >

              {shops.map((item) => {
                return (
                  <YarnMarker
                    key={item.id}
                    anchor={[item.location.lat, item.location.lng]}
                    offset={markerOffset}
                    style={{
                      width: yarnBallWidth,
                      height: yarnBallHeight,
                      backgroundImage: yarnBall,
                      backgroundSize: 'contain',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      zIndex: 1,
                    }}
                  >
                    <button
                      className="marker-button"
                      onClick={() => {
                        dispatch(destination.actions.setSelectedDestination(item))
                        navigate('/destination')
                      }}
                    >
                      {(state.zoom > 6) &&
                        <Overlay
                          anchor={[item.location.lat, item.location.lng]}
                          offset={[23, 25]}>
                          <div className="marker-label">
                            <p>{item.name}</p>
                          </div>
                        </Overlay>
                      }

                    </button>
                  </YarnMarker>
                )
              })}
              {bigScreen && <ZoomControl />}
            </Map >
          </div>
        }
      </div>
      {tallMobile && <AdSpaceBelow />}
      {bigScreen && <AdSpaceDesktop />}
    </div>
  )
};

export default Yarnmap