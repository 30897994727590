import React from 'react';
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'

import destination from '../reducers/destination';

const HomeLink = () => {
  const dispatch = useDispatch();

  return (
    <div>
      <NavLink to='/om'
        onClick={() => {
          dispatch(destination.actions.setSelectedDestination(null))
        }}
      >
        <p className="navbar-item">Kontakt</p>
      </NavLink>
    </div>
  )
};

export default HomeLink;