export const provinces = {
  sverige: [[63.196, 15.817], 4],
  stockholm: [[59.3253, 18.0704], 10],
  göteborg: [[57.7111, 11.9765], 10],
  malmö: [[55.6079, 12.9983], 10],
  blekinge: [[56.2965, 15.2245], 8],
  bohuslän: [[58.3724, 11.6470], 8],
  dalarna: [[61.0078, 14.5402], 6],
  dalsland: [[58.8072, 12.3022], 8],
  gotland: [[57.4686, 18.4872], 8],
  gästrikland: [[60.6145, 16.7664], 8],
  halland: [[56.9299, 12.8113], 8],
  hälsingland: [[61.6355, 15.9581], 7],
  härjedalen: [[62.2777, 13.5168], 7],
  jämtland: [[63.4879, 14.3697], 6],
  lappland: [[66.5744, 18.3925], 6],
  medelpad: [[62.4930, 16.3229], 7],
  norrbotten: [[66.4651, 21.9392], 6],
  närke: [[59.1064, 15.1083], 8],
  skåne: [[55.9059, 13.5876], 8],
  småland: [[57.1280, 14.7784], 7],
  södermanland: [[59.0884, 16.9800], 7],
  uppland: [[59.8509, 17.8665], 8],
  värmland: [[59.8710, 13.1411], 7],
  västerbotten: [[64.4693, 20.2767], 7],
  västergötland: [[58.0475, 13.0975], 7],
  västmanland: [[59.6892, 15.5378], 8],
  ångermanland: [[63.3516, 17.6983], 7],
  öland: [[56.7915, 16.6408], 8],
  östergötland: [[58.4170, 15.6183], 7],
}
