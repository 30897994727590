import React from "react";

const HeaderPlaceholder = () => {
  return (
    <div className="header-placeholder">

    </div>

  )
}
export default HeaderPlaceholder;